import * as React from 'react';
import { DataGrid, esES } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import AdminModal from './AdminModal';

export default function AdminEditableGrid({ rows, updateUserData }) {
  const [editBuffer, setEditBuffer] = React.useState({});
  const [disabledButtons, setDisabledButtons] = React.useState([]);
  const [modalVisible, setModalVisible] = React.useState(false);
  const bufferRef = React.useRef({});

  function handleCellEditCommit(params) {
    const { id, field, value } = params;
    setEditBuffer((prevBuffer) => {
      const updatedBuffer = { ...prevBuffer, [id]: { ...prevBuffer[id], [field]: value } };
      bufferRef.current = updatedBuffer;
      // Mostrar el modal si se modifica el número de cliente
      if (field === 'extension_bdac175cd6a5496290f63c140b584cc5_AccountID') {
        setModalVisible(true);
      }
      return updatedBuffer;
    });
  }

  const handleModificarClick = (numeroDeCliente) => {
    setDisabledButtons((prevDisabledButtons) => [...prevDisabledButtons, numeroDeCliente]);
    setTimeout(() => {
      updateUserData.mutate(bufferRef.current);
    }, 1000);
    setTimeout(() => {
      setDisabledButtons((prevDisabledButtons) => prevDisabledButtons.filter(id => id !== numeroDeCliente));
    }, 3000);
  };

  const renderModificarButton = (params) => {
    return (
      <Button
        disabled={disabledButtons.includes(params.row.id)}
        size="small"
        variant="contained"
        sx={{ backgroundColor: '#FF9636', color: 'white', '&:hover': { backgroundColor: '#FF9636', color: 'white' } }}
        onClick={() => handleModificarClick(params.row.id)}
      >
        Modificar
      </Button>
    );
  };

  const columns = [
    { field: 'extension_bdac175cd6a5496290f63c140b584cc5_AccountID', headerName: 'Número de cliente', flex: 1, editable: true },
    { field: 'razonSocial', headerName: 'Razón social', flex: 1, editable: true },
    { field: 'extension_bdac175cd6a5496290f63c140b584cc5_CUIT', headerName: 'Cuit', flex: 1, editable: true },
    { field: 'givenName', headerName: 'Nombre', flex: 1, editable: true },
    { field: 'surname', headerName: 'Apellido', flex: 1, editable: true },
    { field: 'issuerAssignedID', headerName: 'Email 🔑', flex: 1, editable: false },
    {
      field: 'acciones',
      headerName: 'Acciones',
      flex: 0.6,
      sortable: false,
      editable: false,
      renderCell: renderModificarButton,
    },
  ];

  return (
    <>
      <div style={{ height: 480, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
         autoPageSize
          onCellEditCommit={handleCellEditCommit}
          disableExtendRowFullWidth={true}
          columnBuffer={0}
          localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        />
      </div>
      {modalVisible && (
        <AdminModal
          open={modalVisible}
          onClose={() => setModalVisible(false)}
          title="Aviso"
          message="Recuerda pulsar el botón 'Modificar' y esperar la confirmación de que el proceso ha finalizado correctamente."
          actions={
            <Button onClick={() => setModalVisible(false)} variant="contained" sx={{ backgroundColor: '#3CB9BD', color: 'white' }}>
              Aceptar
            </Button>
          }
        />
      )}
    </>
  );
}
